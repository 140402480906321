<template>
  <div class="locationwiseEntry container">

   <!-- get rid of search message, add arrow icon for drop down menu, white out in and out columns, fix multi item bug -->

    <!-- ---------------------------------------  Inventory List -------------------------------------------------- -->
	
	<p class="h6 text-center text-primary"><b>{{ location }} Inventory Entry</b></p>    
	<br>

	<form>
		<div class="row justify-content-center">
				<label class="font-weight-bold mr-1" for="todate">Date:</label>
				<datepicker v-model="todate" name="todate" @closed="serverCall('getLocationwiseInventory')"
						:disabled-dates="state.disabledDates"></datepicker>
				<label class="ml-4" for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
				<scale-loader :loading="loading"></scale-loader>
		</div>
	</form>		
	<p class="text-danger" align="left">{{alert}}</p>

	<div class="btn-group">
		<button type="button" class="btn btn-secondary  mr-2" 
			v-on:click.prevent="$refs.entryTable.expandAll()">Expand All</button>
		<button type="button" class="btn btn-secondary" 
			v-on:click.prevent="$refs.entryTable.collapseAll()">Collapse All</button>
	</div>
	
	<br><br>

	<vue-good-table
		ref="entryTable"
		:columns="columns"
		:rows="rows"
		:group-options="{enabled: true, collapsable: true}"
		:sort-options="{enabled: false,}"
		styleClass="vgt-table condensed"
	>

		<template slot="table-row" slot-scope="props">
			<span v-if="props.column.field == 'in' || props.column.field == 'out'">
				<form>
					<input class="form-control" type="number" 
						style="font-weight: bold; color: blue;"
						v-model="props.row[props.column.field]"
						@change="changeCell(props.row[props.column.field], props.column.field, props.row)"
					>
				</form>
			</span>
		</template>

	</vue-good-table>


	<main id="main">
	</main>

  </div>
</template>

<script>
import axios from "axios"
import Datepicker from 'vuejs-datepicker';
import ScaleLoader from "@/views/utils/ScaleLoader";
import { firebaseAuth } from '../../firebaseConfig.js';
import Helper from "../utils/Helper.vue"

export default {
	name: 'locationwiseEntry',

	mixins: [
		Helper
	],
	
    components: {
		Datepicker,
        ScaleLoader,
	},
		
	data () {

		return {
			orgId: null,

			alert: null,
			loading: false,

			todate: null,
			location: this.$route.params.location,

			options: [],

			state: {
				// Allow entry for 
				// Current Date - 60 days ---> Curent Date
				disabledDates: {
					to: new Date(new Date().getTime()-(60*24*60*60*1000)),
					from: new Date(),
				}
			},

			rows: [],
			dataList: [],

			columns: 
				[
					{ 
						label: 'Item', 
						field: 'item', 
						type: 'text',
						sortable: false,
					},
					{ 
						label: 'Open', 
						field: 'open', 
						type: 'number',
						width: '100px',
						sortable: false,
					},
					{ 
						label: 'In', 
						field: 'in', 
						type: 'number',
						width: '100px',
						sortable: false,
						tdClass: this.tdClassFunc,
					},
					{ 
						label: 'Out', 
						field: 'out', 
						type: 'number',
						width: '100px',
						sortable: false,
					},
					{ 
						label: 'Close', 
						field: 'close', 
						type: 'number', 
						width: '100px',
						sortable: false,
					},
				],

			rowModified: {},
		
		};
	},

	computed: {
	},

	mounted(){
		// todate = current date
		this.orgId = this.$route.params['orgId']
		this.todate = new Date()
		//this.serverCall('getLocation')	
		this.location = this.$route.params['location']
		this.serverCall('getLocationwiseInventory')	
	},

	watch: {
		$route(to, from) {
			this.serverCall('getLocationwiseInventory')	
		}
	},

	methods: {
		tdClassFunc: function(row) {
			if (row && row.in_equal_out) {
				return 'black'
			}
		},

		changeCell(changedData, column, row) {
			if (column=="In" && row.in_equal_out){
				row.in = row.out
				return
			}

			if (row.in_equal_out){
				row.in = row.out
			}
			
			row.close = parseInt(row.open) + parseInt(row.in) - parseInt(row.out)

			this.rowModified = {
									'date': this.todate,
									'location': this.location,
									'item1': this.rows[row.vgt_id].item,
									'item2': row.item,
									'in': row.in,
									'out': row.out 
							   }

			this.serverCall('updateInventory')	
        },

        serverCall(name) {
            var self = this
			self.alert = null

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getLocationwiseInventory":
								self.getLocationwiseInventory(idToken, this.location, this.todate)
								break;

							case "updateInventory":
								self.updateInventory(idToken, this.rowModified)
								break;

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Please login first."
				}
			})

		},


        // Fetch Inventory for the date
        async getLocationwiseInventory(authToken, location, date) {
            var self = this
			self.alert = null

            if (!location || !date){
                self.alert = "Either date or location not specified."
                return
			}

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Inventory..."

			var postJson = {
				'location': location,
				'toDate': this.getMidDay(date)
			}

			await axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetLocationwiseInventory/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
						console.log("getLocationwiseInventory - error")
						// Reset loading message
						self.loading = false
						self.loadingMsg = null
					}
					else {
						self.rows = response.data.iList
						// Reset loading message
						self.loading = false
						self.loadingMsg = null
					}
				})
				.catch(function (error) {
					self.alert = String(error)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
		},

        // Update Inventory with modified values
        async updateInventory(authToken, rowModified) {
            var self = this
			self.alert = null

			// Set loading message
			self.loading = true
			self.loadingMsg = "Updating Inventory..."

			var postJson = {
				'date': this.getMidDay(rowModified.date), 
				'location': rowModified.location,
				'item1': rowModified.item1,
				'item2': rowModified.item2,
				'in': rowModified.in,
				'out': rowModified.out,
			}

			await axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/UpdateInventory/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(async function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
						console.log("updateInventory - error")
					}
					else {
						//self.rows = response.data.iList
					}
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
				.catch(function (error) {
					self.alert = String(error)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
		},

	},

}
</script>

<style>
	.disabled{
		disabled: true;
	}

	.black {
		color: yellow;
		background-color: #dddddd;
	}

	.blue {
		background-color: #F0F8FF;
	}

input {
  text-align: right;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

</style>

